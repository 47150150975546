import context, { IAppUserContext } from '@truckdown/components-systems';
import { Constants, IInitializer, IUserContext } from "@truckdown/systems";
import { defineAsyncComponent } from 'vue';

import VueGoogleMaps from '@fawmi/vue-google-maps'

import searchForm from "./SearchForm/SearchForm.vue";
import listing from "./Listing/Listing.vue";
import hoursStatus from './HoursStatusComponent/HoursStatusComponent.vue';
import signinOrUpgrade from "./SigninOrUpgrade/SigninOrUpgrade.vue";

class LocationsInitializer implements IInitializer {
    name: string = 'LocationsInitializer';
    order: number = 0;

    initialize(context: IUserContext): Promise<void> {
        let app = (context as IAppUserContext).app;

        app.mixin({
            data: function () {
                return {
                    context: context,
                    constants: Constants
                }
            }
        });

        app.use(VueGoogleMaps, {
            load: {
                key: context.settings['maps-key'] ?? '',
                v: 'weekly'
            }
        });

        app.component('listing-avoid',
            defineAsyncComponent(() => import(/* webpackChunkName: "listing-avoid" */ './Listing/ListingAvoid/ListingAvoid.vue')));
        app.component('listing-favorite',
            defineAsyncComponent(() => import(/* webpackChunkName: "listing-favorite" */ './Listing/ListingFavorite/ListingFavorite.vue')));
        app.component('listing-forward',
            defineAsyncComponent(() => import(/* webpackChunkName: "listing-forward" */ './Listing/ListingForward/ListingForward.vue')));
        app.component('listing-notes',
            defineAsyncComponent(() => import(/* webpackChunkName: "listing-notes" */ './Listing/ListingNotes/ListingNotes.vue')));
        app.component('listing-rate',
            defineAsyncComponent(() => import(/* webpackChunkName: "listing-rate" */ './Listing/ListingRate/ListingRate.vue')));
        app.component('listing-remove',
            defineAsyncComponent(() => import(/* webpackChunkName: "listing-remove" */ './Listing/ListingRemove/ListingRemove.vue')));
        app.component('listing-upgrade',
            defineAsyncComponent(() => import(/* webpackChunkName: "listing-upgrade" */ './Listing/ListingUpgrade/ListingUpgrade.vue')));
        app.component('listing-report-update',
            defineAsyncComponent(() => import(/* webpackChunkName: "listing-report-update" */ './Listing/ListingReportUpdate/ListingReportUpdate.vue')));


        app.component('search-form', searchForm);
        app.component('listing', listing);
        app.component('hours-status', hoursStatus);
        app.component('signin-or-upgrade', signinOrUpgrade);
        return Promise.resolve();
    }
}

context.registerService({
    name: 'IInitializer',
    activator: async () => {
        return new LocationsInitializer();
    }
});
